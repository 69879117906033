import { useWindowScroll } from '@services/hooks'
import bottomRightBackground from '@static/images/main/scroll-section/bottom-right.png'
import topLeftBackground from '@static/images/main/scroll-section/top-left.png'
import { motion, useTransform, useViewportScroll } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import MobilePage from './mobile-page'
import ScrollSectionsList, { ScrollSectionsListItemProps } from './sections'
import './style.scss'

const useScrollHandler = (
  elementTop: number,
  elementHeight: number,
  numberOfSections: number
) => {
  const { scrollY } = useViewportScroll()
  return useTransform(scrollY, latest => {
    const endY = elementTop + elementHeight * numberOfSections
    return latest > endY ? -latest + endY : 0
  })
}

const useSectionHeight = ref => {
  const [elementTop, setElementTop] = useState(0)
  const [elementHeight, setElementHeight] = useState(0)
  useEffect(() => {
    if (!ref.current) return
    const setValues = () => {
      setElementTop(ref?.current?.offsetTop)
      setElementHeight(window.innerHeight)
    }

    setValues()
    document.addEventListener('load', setValues)
    window.addEventListener('resize', setValues)

    setTimeout(setValues, 500)
    return () => {
      document.removeEventListener('load', setValues)
      window.removeEventListener('resize', setValues)
    }
  }, [ref])
  return [elementTop, elementHeight]
}

const useScrollPageData = (
  elementTop: number,
  elementHeight: number,
  ScrollSectionsList: ScrollSectionsListItemProps[]
): ScrollSectionsListItemProps => {
  const pageOffset = useWindowScroll()
  const pageNumber =
    Math.trunc((pageOffset - (elementTop * 3) / 4) / elementHeight) || 0

  if (pageNumber > ScrollSectionsList.length - 1)
    return ScrollSectionsList[ScrollSectionsList.length - 1]
  else if (pageNumber < 0) return ScrollSectionsList[0]
  return ScrollSectionsList[pageNumber]
}

const ScrollSection = () => {
  const ref = useRef(null)
  const [elementTop, elementHeight] = useSectionHeight(ref)
  const y = useScrollHandler(
    elementTop,
    elementHeight,
    ScrollSectionsList.length
  )

  const page = useScrollPageData(elementTop, elementHeight, ScrollSectionsList)

  return (
    <>
      <div ref={ref} className={'position-relative d-none d-lg-block'}>
        <motion.div
          style={{
            height: '100vh',
            width: '100%',
            position: 'sticky',
            top: y,
            backgroundColor: page.backgroundColor,
            transition: '0.2s background-color linear',
            zIndex: -1
          }}
        >
          <img
            src={topLeftBackground}
            className="top-0 left-0 position-absolute"
            style={{ zIndex: -1, width: '40%' }}
          />
          <img
            src={bottomRightBackground}
            className="bottom-0 end-0 position-absolute w-25"
            style={{ zIndex: -1 }}
          />
          <Container
            className={'align-self-center h-100 position-relative'}
            style={{ zIndex: 1 }}
          >
            <Row className="h-100" style={{ maxHeight: '100vh' }}>
              <Col
                sm={5}
                className="align-items-center justify-content-center d-flex"
                style={{ maxHeight: '100vh' }}
              >
                <div className="pt-5 pb-5">
                  <h1 className="fw-bold mb-4 text-white">
                    Target survey panels based on verified purchase behaviour.
                  </h1>
                  <h4 className={'text-white mb-3'}>
                    Target your audience from actual transaction data to
                    demographic profiles. This means no matter how big or small
                    your cohort is you'll have accurate, real data.
                  </h4>
                </div>
              </Col>
              <Col
                sm={7}
                className="align-items-center justify-content-center d-flex flex-column"
              />
            </Row>
          </Container>
        </motion.div>

        <div
          className="position-relative"
          style={{ top: '-100vh', height: '300vh' }}
        >
          {ScrollSectionsList.map(i => (
            <div
              key={i.text}
              style={{
                height: `100vh`,
                width: '100%'
              }}
            >
              <Container
                className={'align-self-center h-100 position-relative'}
                style={{ zIndex: 1 }}
              >
                <Row className="h-100" style={{ maxHeight: '100vh' }}>
                  <Col
                    sm={5}
                    className="align-items-center justify-content-center d-flex"
                    style={{ maxHeight: '100vh' }}
                  />
                  <Col
                    sm={7}
                    className="align-items-center justify-content-center d-flex flex-column"
                  >
                    <div
                      className={
                        'h-100 w-100 d-flex align-items-center' +
                        ' justify-content-center flex-column ps-3'
                      }
                      style={{ height: '100vh' }}
                      key={i.text}
                    >
                      <img
                        src={i.image}
                        className="w-100"
                        style={{
                          position: 'relative',
                          zIndex: 99999
                        }}
                      />
                      <h2 className="text-white fw-bolder ms-5">{i.text}</h2>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          ))}
        </div>
      </div>
      <div className={'d-lg-none'}>
        <MobilePage />
      </div>
    </>
  )
}

export default ScrollSection
