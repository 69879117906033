import numberOfReceipts from '@static/images/main/number-of-receipts.png'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './style.scss'

const HeroSection = () => (
  <section style={{ backgroundColor: '#4C68AB' }} className="pt-5 pb-5">
    <Container>
      <Row className="pt-2 pb-2">
        <Col
          lg={{ order: 1, span: 6 }}
          md={{ order: 2, span: 12 }}
          sm={{ order: 2, span: 12 }}
          xs={{ order: 2 }}
          className="image-wrapper"
        >
          <img
            src={numberOfReceipts}
            className="receipt-image"
            alt={'hero-image'}
          />
        </Col>
        <Col
          lg={{ order: 2, span: 6 }}
          md={{ order: 1 }}
          sm={{ order: 1 }}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className="text-wrapper">
            <h1 className="fw-bolder text-white mb-4">
              Unlock the power from purchase behaviour.
            </h1>
            <h4 className=" text-white">
              U Ask is powered by the largest receipt panel in Australia, with
              over 10 million shopping receipts.
            </h4>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
)

export default HeroSection
