import { HowItWorksListProps } from '@screens/main/sections/how-it-works/sections'
import { useWindowScroll } from '@services/hooks'
import { useTransform, useViewportScroll } from 'framer-motion'
import { useEffect, useState } from 'react'

export const useScrollHandler = (
  sectionTop: number,
  sectionHeight: number,
  numberOfSections: number
) => {
  const { scrollY } = useViewportScroll()
  return useTransform(scrollY, latest => {
    const endY = sectionTop + sectionHeight * numberOfSections
    return latest > endY ? -latest + endY : 0
  })
}

export const useSectionHeight = ref => {
  const [sectionTop, setSectionTop] = useState(0)
  const [sectionHeight, setSectionHeight] = useState(0)
  useEffect(() => {
    if (!ref.current) return
    const setValues = () => {
      setSectionTop(ref?.current?.offsetTop)
      setSectionHeight(window.innerHeight)
    }

    setValues()
    document.addEventListener('load', setValues)
    window.addEventListener('resize', setValues)

    setTimeout(setValues, 500)
    return () => {
      document.removeEventListener('load', setValues)
      window.removeEventListener('resize', setValues)
    }
  }, [ref])
  return [sectionTop, sectionHeight]
}

export const useImageWidth = ref => {
  const [imageHeight, setImageHeight] = useState(0)
  const [imageWidth, setImageWidth] = useState(0)
  useEffect(() => {
    if (!ref.current) return
    const setValues = () => {
      setImageHeight(ref?.current?.offsetHeight)
      setImageWidth(ref?.current?.offsetWidth)
    }

    setValues()
    document.addEventListener('load', setValues)
    window.addEventListener('resize', setValues)

    setTimeout(setValues, 500)
    return () => {
      document.removeEventListener('load', setValues)
      window.removeEventListener('resize', setValues)
    }
  }, [ref])
  return [imageHeight, imageWidth]
}

export const useScrollPageData = (
  sectionTop: number,
  sectionHeight: number,
  ScrollSectionsList: HowItWorksListProps[]
): HowItWorksListProps => {
  const pageOffset = useWindowScroll()
  const pageNumber = Math.trunc((pageOffset - sectionTop) / sectionHeight) || 0

  if (pageNumber > ScrollSectionsList.length - 1)
    return ScrollSectionsList[ScrollSectionsList.length - 1]
  else if (pageNumber < 0) return ScrollSectionsList[0]
  return ScrollSectionsList[pageNumber]
}
