import HowItWorksList from '@screens/main/sections/how-it-works/sections'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './style.scss'

const StaticMobileSection = () => {
  return (
    <section style={{ backgroundColor: '#FAF6F6' }}>
      <Container
        className={'align-self-center h-100 position-relative'}
        style={{ zIndex: 1 }}
      >
        <div className="h-100">
          <h1 className="fw-bold pt-5 text-center pb-3">How it works</h1>
          <Row>
            <Col
              md={24}
              className=" d-flex justify-content-start align-items-start"
            >
              <div className="pt-1 pb-5">
                {HowItWorksList.map((i, index) => (
                  <div className="hiw-card" key={index}>
                    <h2 className="fw-bold">{i.title}</h2>
                    <p className="mb-0 typo-p">{i.description}</p>
                  </div>
                ))}
                <div className="d-flex justify-content-center">
                  <Button
                    className="text-white"
                    href="https://app.uask.io"
                    target="_blank"
                  >
                    Try for free
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default StaticMobileSection
