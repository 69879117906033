import { motion, useTransform, useViewportScroll } from 'framer-motion'
import React from 'react'

const useCircles = (
  sectionTop,
  sectionHeight,
  imageHeight,
  imageWidth,
  numberOfSections,
  outputYRange,
  outputXRange,
  constant = 50
) => {
  const { scrollY } = useViewportScroll()
  //  @ts-ignore
  const range = [...Array(numberOfSections).keys()].map(
    i => sectionTop + sectionHeight * i
  )
  const yellowX = useTransform(scrollY, range, outputXRange)
  const yellowY = useTransform(scrollY, range, outputYRange)

  return {
    //  @ts-ignore
    y: useTransform(yellowY, latest => latest - constant),
    //  @ts-ignore
    x: useTransform(yellowX, latest => latest - constant)
  }
}

interface CircleProps {
  sectionTop: number
  sectionHeight: number
  imageHeight: number
  imageWidth: number
  backgroundColor: string
  size: number
  numberOfElements: number
  offset: number
}

const BackgroundCircle = ({
  sectionTop,
  sectionHeight,
  imageHeight,
  imageWidth,
  backgroundColor,
  size,
  numberOfElements,
  offset
}: CircleProps) => {
  const outputYRange = [0, 0, imageHeight, imageHeight]
  const outputXRange = [0, imageWidth, imageWidth, 0]
  const offsetOutputY = outputYRange
    .slice(offset, outputYRange.length)
    .concat(...outputYRange.slice(0, offset))

  const offsetOutputX = outputXRange
    .slice(offset, outputXRange.length)
    .concat(...outputXRange.slice(0, offset))
  const { y, x } = useCircles(
    sectionTop,
    sectionHeight,
    imageHeight,
    imageWidth,
    numberOfElements,
    offsetOutputY,
    offsetOutputX
  )
  return (
    <motion.div
      className="position-absolute"
      style={{
        y,
        x,
        backgroundColor,
        height: size,
        width: size,
        borderRadius: '100%'
      }}
    />
  )
}
export default BackgroundCircle
