import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import clientImages from './client-images'
import './style.scss'

const TrustedBySection = () => (
  <section className="position-relative">
    <div className="trusted-by-section">
      <h4 className="text-center mt-0 mb-3">TRUSTED BY</h4>

      <Container className="pb-5" fluid={'md'}>
        <Row
          className="justify-content-center mx-lg-5"
          lg={4}
          md={4}
          sm={2}
          xs={2}
        >
          {clientImages.map((i, index) => (
            <Col key={index} style={{ height: 80 }} className="p-2">
              <img src={i} className={'client-image'} alt={'client-images'} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  </section>
)

export default TrustedBySection
