import BaseContainer from '@components/base-container'
import ReceiptCounterFooter from '@components/receipt-counter-footer'
import * as React from 'react'
import HeroSection from './sections/hero'
import HowItWorksSection from './sections/how-it-works'
import NumberOfReceiptsSection from './sections/receipt-number'
import ScrollSection from './sections/scroll'
import HeroInterstitialSection from './sections/trusted-by'

const IndexPage = () => {
  return (
    <BaseContainer>
      <HeroSection />
      <HeroInterstitialSection />
      <NumberOfReceiptsSection />
      <ScrollSection />
      <HowItWorksSection />
      <ReceiptCounterFooter sectionBackGround="#FAF6F6" />
    </BaseContainer>
  )
}

export default IndexPage
