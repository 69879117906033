import benAndJerrys
  from '@static/images/main/trusted-clients/ben-and-jerrys.png'
import dove from '@static/images/main/trusted-clients/dove.png'
import lavazza from '@static/images/main/trusted-clients/lavazza.png'
import nestle from '@static/images/main/trusted-clients/nestle.png'
import pm from '@static/images/main/trusted-clients/pm.png'
import purina from '@static/images/main/trusted-clients/purina.png'
import unilever from '@static/images/main/trusted-clients/unilever.png'

const clientImages = [
  benAndJerrys,
  dove,
  nestle,
  unilever,
  purina,
  lavazza,
  pm
]

export default clientImages
