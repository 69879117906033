import brandBuyer from '@static/images/main/scroll-section/brand-buyer.png'
import categoryBuyer from '@static/images/main/scroll-section/category-buyer.png'
import demographics from '@static/images/main/scroll-section/demographics.png'
import retailerBuyer from '@static/images/main/scroll-section/retail-buyer.png'

export interface ScrollSectionsListItemProps {
  backgroundColor: string
  image?: any
  text: string
}

const ScrollSectionsList: ScrollSectionsListItemProps[] = [
  {
    backgroundColor: '#FF555E',
    image: demographics,
    text: 'Demographics'
  },
  {
    backgroundColor: '#3D5E9F',
    image: retailerBuyer,
    text: 'What retailers they shop at?'
  },
  {
    backgroundColor: '#2D4980',
    image: categoryBuyer,
    text: 'What categories they buy?'
  },
  {
    backgroundColor: '#6c7c96',
    image: brandBuyer,
    text: 'What brands they love?'
  }
]

export const AllSectionText = ScrollSectionsList.map(i => i.text)

export default ScrollSectionsList
