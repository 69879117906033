import first from '@static/images/main/how-it-works/first.png'
import fourth from '@static/images/main/how-it-works/fourth.png'
import second from '@static/images/main/how-it-works/second.png'
import third from '@static/images/main/how-it-works/third.png'

export interface HowItWorksListProps {
  image?: any
  title: string
  description: string
}

const HowItWorksList: HowItWorksListProps[] = [
  {
    image: first,
    title: 'Real transaction data',
    description:
      'Our panellists provide us with a continuous stream of rich transaction data, from their receipts upload via our ecosystem of mobile apps.'
  },
  {
    image: second,
    title: 'Say goodbye to claim purchase behaviour',
    description:
      'You can create a target audience base on the items our panellists buys  '
  },
  {
    image: third,
    title: 'Automated market research',
    description:
      'We leverage advanced algorithms and machine learning to give you better solutions at more affordable prices.'
  },
  {
    image: fourth,
    title: 'Better ROI on your data',
    description:
      'Get useful insights within 24 hours at half the cost of ad hoc projects.'
  }
]

export const AllSectiontitle = HowItWorksList.map(i => i.title)

export default HowItWorksList
