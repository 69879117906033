import bottomRightBackground from '@static/images/main/scroll-section/bottom-right.png'
import topLeftBackground from '@static/images/main/scroll-section/top-left.png'
import React from 'react'
import { Container } from 'react-bootstrap'
import ScrollSectionsList from './sections'
import './style.scss'

const MobileScrollSection = () => {
  return (
    <section style={{ backgroundColor: ScrollSectionsList[0].backgroundColor }}>
      <Container className={'position-relative pb-5'}>
        <img
          src={topLeftBackground}
          className="top-0 left-0 position-absolute"
          style={{ width: '40%' }}
        />
        <img
          src={bottomRightBackground}
          className="bottom-0 end-0 position-absolute w-25"
        />
        <div className="pt-5 pb-3">
          <h1 className="fw-bold mb-4 text-white">
            Target survey panels based on verified purchase behaviour.
          </h1>
          <h4 className={'text-white mb-3'}>
            Target your audience from actual transaction data to demographic
            profiles. This means no matter how big or small your cohort is
            you'll have accurate, real data.
          </h4>
        </div>

        {ScrollSectionsList.map(i => (
          <div key={i.text} className={'w-100 text-center mb-3'}>
            <img src={i.image} className="w-75" />

            <h3 className="text-white fw-bolder mt-2 mb-5">{i.text}</h3>
          </div>
        ))}
      </Container>
    </section>
  )
}

export default MobileScrollSection
