import { Color, Spacing } from '@services/styles'
import heroImage from '@static/images/main/hero.png'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './style.scss'

const heroPadding = Spacing.LARGE * 3
const HeroSection = () => {
  return (
    <>
      <section
        className="pt-5 pb-5"
        style={{
          background: 'radial-gradient(circle at top right, #283A65, #1E2D4F)'
          // clipPath: 'url(#c1)'
        }}
      >
        <Container>
          <Row>
            <Col
              lg={6}
              md={12}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className="pt-5 pb-5">
                <h1
                  style={{
                    color: Color.SECONDARY,
                    marginBottom: Spacing.TINY,
                    display: 'initial',
                    //  @ts-ignore
                    '-webkit-text-stroke': '1px white',
                    lineHeight: 0.7
                  }}
                  className="fw-bold typo-h1"
                >
                  talk
                </h1>
                <h1 className="mt-3 text-white fw-bolder typo-h2">
                  to the real buyers of your products
                </h1>
                <h3 className="mt-4 text-white typo-h3">
                  Unlike other survey products, U Ask uses actual transaction
                  data, so brands always know they’re asking the right buyer.
                </h3>
                <Button
                  className="mt-4 text-white"
                  type="primary"
                  href="https://app.uask.io"
                  target="_blank"
                >
                  ask a question now
                </Button>
              </div>
            </Col>
            <Col lg={6} md={24} className="image-wrapper">
              <img src={heroImage} className="hero-image" alt={'hero-image'} />
            </Col>
          </Row>
        </Container>

        <svg width="0" height="0">
          <defs>
            <clipPath id="c1" clipPathUnits="objectBoundingBox">
              <path d="M0,0 1,0 1,0.9 C 1,0.9, 0.77,1, 0.5,1 0.23,1, 0,0.9,0,0.9z" />
            </clipPath>
          </defs>
        </svg>
      </section>
    </>
  )
}

export default HeroSection
