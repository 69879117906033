import { motion } from 'framer-motion'
import React, { useRef } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import BackgroundCircle from './components/background-circle'
import StaticMobileSection from './components/mobile-page'
import TextSection from './components/text-section'
import {
  useImageWidth,
  useScrollHandler,
  useScrollPageData,
  useSectionHeight
} from './hooks'
import HowItWorksList from './sections'
import './style.scss'

const textData = [
  { range: [100, 30, 20, 12.5] },
  { range: [30, 100, 30, 20] },
  { range: [20, 30, 100, 75] },
  { range: [12.5, 20, 30, 100] }
]

const HowItWorksSection = () => {
  const sectionRef = useRef(null)
  const [sectionTop, sectionHeight] = useSectionHeight(sectionRef)

  const imageRef = useRef(null)
  const [imageHeight, imageWidth] = useImageWidth(imageRef)
  const y = useScrollHandler(sectionTop, sectionHeight, HowItWorksList.length)
  const page = useScrollPageData(sectionTop, sectionHeight, HowItWorksList)

  const handleScrollTo = (index: number) => {
    let top = sectionTop + sectionHeight * index
    window.scroll({ top, behavior: 'smooth' })
  }

  const circleProps = {
    sectionTop,
    sectionHeight,
    imageHeight,
    imageWidth,
    numberOfElements: HowItWorksList.length
  }
  const textProps = {
    sectionTop,
    sectionHeight,
    numberOfElements: HowItWorksList.length
  }

  return (
    <>
      <div ref={sectionRef} className={'d-none d-lg-block'}>
        <motion.div
          className={'d-flex align-items-center'}
          style={{
            height: '100vh',
            width: '100%',
            position: 'sticky',
            top: y,
            backgroundColor: '#FAF6F6',
            transition: '0.2s background-color linear'
          }}
        >
          <Container
            className={'align-self-center h-100 position-relative'}
            style={{ zIndex: 1 }}
          >
            <div className="h-100 justify-content-center align-items-center d-flex flex-column">
              <h1 className="fw-bold pt-5 text-center pb-3">How it works</h1>
              <Row className="d-flex">
                <Col
                  sm={6}
                  className="align-items-center justify-content-center d-flex"
                >
                  <div className="pt-1 pb-5">
                    <div style={{ height: 500 }}>
                      {textData.map(({ range }, index) => (
                        <TextSection
                          key={index}
                          {...textProps}
                          range={range}
                          item={HowItWorksList[index]}
                          onClick={_ => handleScrollTo(index)}
                        />
                      ))}
                    </div>
                    <Button
                      className="text-white"
                      href="https://app.uask.io"
                      target="_blank"
                    >
                      Try for free
                    </Button>
                  </div>
                </Col>
                <Col
                  sm={6}
                  className="align-items-center justify-content-center d-flex"
                >
                  <div className="w-50 position-relative" ref={imageRef}>
                    <BackgroundCircle
                      {...circleProps}
                      backgroundColor="rgba(255,226,170,0.5)"
                      size={110}
                      offset={0}
                    />
                    <BackgroundCircle
                      {...circleProps}
                      backgroundColor="rgba(176,217,255,0.5)"
                      size={40}
                      offset={1}
                    />
                    <BackgroundCircle
                      {...circleProps}
                      backgroundColor="rgba(213,220,233,0.5)"
                      size={70}
                      offset={2}
                    />
                    <img src={page.image} className="w-100" alt={page.title} />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </motion.div>

        {HowItWorksList.map(i => (
          <div
            style={{
              height: `100vh`,
              width: '100%'
            }}
          />
        ))}
      </div>

      <div className="d-lg-none">
        <StaticMobileSection />
      </div>
    </>
  )
}

export default HowItWorksSection
