import { HowItWorksListProps } from '@screens/main/sections/how-it-works/sections'
import { motion, useTransform, useViewportScroll } from 'framer-motion'
import React from 'react'
import './style.scss'

const useTextSection = (
  sectionTop,
  sectionHeight,
  numberOfSections,
  outputRange
) => {
  const { scrollY } = useViewportScroll()
  //  @ts-ignore
  const range = [...Array(numberOfSections).keys()].map(
    i => sectionTop + sectionHeight * i
  )
  const output = useTransform(scrollY, range, outputRange)
  const scale = useTransform(output, [0, 100], [0.25, 1])
  const height = useTransform(output, [0, 100], [10, 180])
  const y = useTransform(output, [0, 100], [-200, 200])
  const backgroundColor = useTransform(output, latest => {
    if (latest > 80) {
      return 'rgba(255,255,255,1)'
    }
    return 'rgba(255,255,255,0)'
  })
  const boxShadow = useTransform(output, latest => {
    if (latest > 80) {
      return '0px 2px 4px rgba(0, 0, 0, 0.03), 0px 4px 17px rgba(0, 0, 0, 0.05)'
    }
    return ''
  })
  const display = useTransform(output, latest => {
    if (latest > 80) {
      return 'block'
    }
    return 'none'
  })
  return {
    scale,
    height,
    y,
    backgroundColor,
    boxShadow,
    display
  }
}

interface TextSectionProps {
  sectionTop: number
  sectionHeight: number
  numberOfElements: number
  range: number[]
  item: HowItWorksListProps
  onClick: any
}

const TextSection = ({
  sectionTop,
  sectionHeight,
  numberOfElements,
  range,
  item,
  onClick
}: TextSectionProps) => {
  const { scale, height, backgroundColor, boxShadow, display } = useTextSection(
    sectionTop,
    sectionHeight,
    numberOfElements,
    range
  )

  return (
    <motion.div
      onClick={onClick}
      style={{
        height,
        backgroundColor,
        borderRadius: '1rem',
        transition: 'all 0.2s linear',
        boxShadow,
        display
      }}
      className="p-3 d-flex mb-3 text-section-component"
    >
      <motion.div style={{ scale, transformOrigin: 'top left' }}>
        <h2 className="fw-bold">{item.title}</h2>
        <motion.div style={{ display }}>
          <p>{item.description}</p>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}
export default TextSection
